<template>
    <div :class="shadow ? 'publicBox' : 'publicBox publicBoxNoshadow'">
        <!-- 顶部导航 -->
        <div class="nav" :class="isNav ? 'navs' : ''">
            <div class="nav_logo">
                <div class="nav_logo_img" @click="backIndexTop">
                    <img src="../assets/images/logo3.png" height="30" alt=""/>
                </div>
                <router-link to="/vip" class="nav_logo_gg">
                    <img src="../assets/images/ad1.gif" height="24" alt=""/>
                </router-link>
            </div>
            <div class="nav_list">
                <div class="nav_ul">
                    <div>
                        <div @click="refreshFun('/')" class="nav_ul_li_title">
                            <span :class="routerIndex == 1 ? 'active' : ''">首页</span>
                        </div>
                    </div>
                    <!-- <div class="nav_ul_li">
                      <el-popover
                        placement="bottom"
                        width="557"
                        trigger="hover"
                        :visible-arrow="false">
                        <div class="nav_li_down_box">
                          <div class="nav_li_down_search">
                            <div class="nav_search">
                              <input type="text" placeholder="搜索你喜欢的字体素材">
                              <div class="nav_search_button"></div>
                            </div>
                          </div>
                          <div class="nav_second">
                            <div class="nav_second_li">
                              <div class="nav_second_title">
                                <img src="../assets/images/icon_zhuti@2x.png" alt="">
                                <span>主题</span>
                              </div>
                              <div class="nav_second_r">
                                <ul class="nav_second_ul">
                                  <li :class="themeIndex == index?'active':''" @click="navtabFun(index,item.id)" v-for="(item,index) in theme_list" :key="index">{{item.title}}</li>
                                </ul>
                              </div>
                            </div>
                            <div class="nav_second_li" v-for="(item,index) in other_list" :key="index">
                              <div class="nav_second_title">
                                <img :src="item.thumb" alt="">
                                <span>{{item.name}}</span>
                              </div>
                              <div class="nav_second_r">
                                <ul class="nav_second_ul">
                                  <li v-for="(items,indexs) in item.cate_list" :key="indexs" @click="toJumpFun(item.id,indexs)">{{items.name}}</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <el-button slot="reference"><router-link to="/material" class="down nav_ul_li_title">字体素材 <img src="../assets/images/jiantou01@2x.png" alt=""></router-link></el-button>
                      </el-popover>
                      <img class="nav_ul_li_new" src="../assets/images/N@2x.png" alt="">
                    </div> -->

                    <div class="nav_ul_li">
                        <!-- <img class="nav_ul_li_new" src="../assets/images/N@2x.png" alt=""> -->
                        <el-popover
                                placement="bottom"
                                width="557"
                                trigger="hover"
                                :visible-arrow="false"
                                @show="getTitleFun"
                        >
                            <div class="nav_li_down_box">
                                <div class="nav_second">
                                    <!-- v-for="(item,index) in navSecond" :key="index" -->
                                    <div
                                            class="nav_second_li"
                                            v-for="(item, index) in videoClass"
                                            :key="index"
                                    >
                                        <div class="nav_second_title">
                                            <img
                                                    v-if="item.name == '类别'"
                                                    src="../assets/images/leibie@2x.png"
                                                    alt=""
                                            />
                                            <img
                                                    v-if="item.name == '风格'"
                                                    src="../assets/images/icon_fengge@2x.png"
                                                    alt=""
                                            />
                                            <img
                                                    v-if="item.name == '级别'"
                                                    src="../assets/images/jibie@2x.png"
                                                    alt=""
                                            />
                                            <img
                                                    v-if="item.name == '系列'"
                                                    src="../assets/images/xilie.svg"
                                                    style="width: 12px"
                                                    alt=""
                                            />
                                            <span>{{ item.name }}</span>
                                        </div>
                                        <div class="nav_second_r">
                                            <ul class="nav_second_ul">
                                                <template v-for="(items, indexs) in item.list">
                                                    <li
                                                            v-if="indexs <= navindex[index]"
                                                            :key="indexs"
                                                            :ref="'getTitleRefss' + index"
                                                            @click="toVideoJumpFun(items.id, indexs)"
                                                    >
                                                        {{ items.name }}
                                                    </li>
                                                    <!-- <li v-for="(items,indexs) in item.list" :key="indexs" @click="toVideoJumpFun(item.id,indexs)">{{items.name}}</li> -->
                                                </template>
                                                <li v-if="item.list.length - 1 > navindex[index]">
                                                    <div
                                                            @click="classMoreFun"
                                                            :class="
                              item.name == '系列'
                                ? 'nav_second_ul_more nav_second_ul_more2'
                                : 'nav_second_ul_more'
                            "
                                                    >
                                                        更多<img src="../assets/images/unfold.png" alt=""/>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <el-button slot="reference">
                                <div @click="refreshFun('video')" class="nav_ul_li_title">
                  <span :class="routerIndex == 3 ? 'active' : ''"
                  >视频教程</span
                  >
                                </div>
                            </el-button>
                        </el-popover>
                    </div>

                    <div class="nav_ul_li">
                        <el-popover
                                placement="bottom"
                                width="557"
                                trigger="hover"
                                :visible-arrow="false"
                        >
                            <div class="nav_li_down_box">
                                <div class="nav_li_down_search">
                                    <div class="nav_search">
                                        <input
                                                type="text"
                                                placeholder="搜索你喜欢的字体素材"
                                                v-model="searchOne"
                                                @keyup.enter="searchOneFun"
                                        />
                                        <div class="nav_search_button" @click="searchOneFun"></div>
                                    </div>
                                </div>
                                <div class="nav_second">
                                    <!-- v-for="(item,index) in navSecond" :key="index" -->
                                    <div class="nav_second_li">
                                        <div class="nav_second_title">
                                            <img src="../assets/images/icon_zhuti@2x.png" alt=""/>
                                            <span>主题</span>
                                        </div>
                                        <div class="nav_second_r">
                                            <ul class="nav_second_ul">
                                                <li
                                                        class="nav_second_ul_li"
                                                        @click="navtabFun(index, item.id)"
                                                        v-for="(item, index) in theme_list"
                                                        :key="index"
                                                >
                                                    {{ item.title }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <template v-for="(item, index) in other_list">
                                        <div
                                                class="nav_second_li"
                                                :key="index"
                                                v-if="item.name == '风格'"
                                        >
                                            <div class="nav_second_title">
                                                <img src="../assets/images/icon_fengge@2x.png" alt=""/>
                                                <span>{{ item.name }}</span>
                                            </div>
                                            <div class="nav_second_r">
                                                <ul class="nav_second_ul">
                                                    <template v-for="(items, indexs) in item.cate_list">
                                                        <li
                                                                v-if="indexs < 11"
                                                                :key="indexs"
                                                                @click="toJumpFun(item.id, indexs)"
                                                        >
                                                            {{ items.name }}
                                                        </li>
                                                    </template>
                                                    <li v-if="item.cate_list.length > 11">
                                                        <router-link
                                                                to="/material"
                                                                class="nav_second_ul_more"
                                                        >更多<img
                                                                src="../assets/images/unfold.png"
                                                                alt=""
                                                        />
                                                        </router-link>
                                                    </li>
                                                    <!-- <li><router-link to="/material" class="nav_second_ul_more">更多<img src="../assets/images/unfold.png" alt=""></router-link></li> -->
                                                </ul>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-for="(item, index) in other_list">
                                        <div
                                                class="nav_second_li"
                                                :key="index"
                                                v-if="item.name == '行业'"
                                        >
                                            <div class="nav_second_title">
                                                <img src="../assets/images/icon_hangye@2x.png" alt=""/>
                                                <span>{{ item.name }}</span>
                                            </div>
                                            <div class="nav_second_r">
                                                <ul class="nav_second_ul">
                                                    <template v-for="(items, indexs) in item.cate_list">
                                                        <li
                                                                v-if="indexs < 11"
                                                                :key="indexs"
                                                                @click="toJumpFun(item.id, indexs)"
                                                        >
                                                            {{ items.name }}
                                                        </li>
                                                    </template>
                                                    <li v-if="item.cate_list.length > 11">
                                                        <router-link
                                                                to="/material"
                                                                class="nav_second_ul_more"
                                                        >更多<img
                                                                src="../assets/images/unfold.png"
                                                                alt=""
                                                        />
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <!-- <el-button slot="reference"><router-link to="/material" class="down nav_ul_li_title"><span :class="routerIndex == 2? 'active':''">字体素材</span> <img class="down" src="../assets/images/jiantou01@2x.png" alt=""><img class="up" src="../assets/images/indexzhankai.png" alt=""></router-link></el-button> -->
                            <el-button slot="reference">
                                <div
                                        @click="refreshFun('material')"
                                        class="down nav_ul_li_title"
                                >
                  <span :class="routerIndex == 2 ? 'active' : ''"
                  >字体素材</span
                  >
                                </div>
                            </el-button>
                        </el-popover>

                        <!-- <img class="nav_ul_li_new" src="../assets/images/N@2x.png" alt=""> -->
                    </div>

                    <div class="nav_ul_li">
                        <el-popover
                                placement="bottom"
                                width="630"
                                trigger="hover"
                                :visible-arrow="false"
                        >
                            <div class="nav_daily_down_box">
                                <img
                                        @click="refreshFun('ranking')"
                                        src="../assets/images/daily1.png"
                                        alt=""
                                />
                                <img
                                        @click="refreshFun('dailyWord')"
                                        src="../assets/images/daily3.png"
                                        alt=""
                                />
                                <img
                                        @click="refreshFun('fontSearch')"
                                        src="../assets/images/daily2.png"
                                        alt=""
                                />
                            </div>
                            <!-- <el-button slot="reference"><h5 class="down nav_ul_li_title"><router-link to="/ranking" class="nav_ul_li_title"><span :class="routerIndex == 5? 'active':''">每日一字</span></router-link> <img class="down" src="../assets/images/jiantou01@2x.png" alt=""><img class="up" src="../assets/images/indexzhankai.png" alt=""></h5></el-button> -->
                            <el-button slot="reference">
                                <h5 class="down nav_ul_li_title">
                                    <div @click="refreshFun('ranking')" class="nav_ul_li_title">
                    <span :class="routerIndex == 5 ? 'active' : ''"
                    >每日一字</span
                    >
                                    </div>
                                </h5>
                            </el-button>
                        </el-popover>
                    </div>
                    <div class="nav_ul_li">
                        <el-popover
                                placement="bottom"
                                width="195"
                                trigger="hover"
                                :visible-arrow="false"
                        >
                            <div class="nav_daily_down_box">
                                <ul class="area">
                                    <li @click="toArea(1)">周末卷卷堂</li>
                                    <li @click="toArea(2)">优秀作业专栏</li>
                                </ul>
                            </div>

                            <el-button slot="reference">
                                <h5 class="down nav_ul_li_title">
                                    <div @click="refreshFun('arrondi')" class="nav_ul_li_title">
                    <span :class="routerIndex == 8 ? 'active' : ''"
                    >活动专区</span
                    >
                                    </div>
                                </h5>
                            </el-button>
                        </el-popover>
                    </div>
                    <div class="nav_ul_li">
                        <el-button slot="reference"
                        >
                            <div
                                    @click="refreshFun('matchIndex')"
                                    class="down nav_ul_li_title"
                            >
                <span :class="routerIndex == 7 ? 'active' : ''"
                >刘兵克字体奖</span
                >
                            </div>
                        </el-button
                        >
                    </div>
                    <!-- 	<el-popover
                        placement="bottom"
                        width="557"
                        trigger="hover"
                        :visible-arrow="false">
                        <div class="nav_li_down_box">
                          <div class="nav_li_down_search" style="margin-bottom:0">
                            <div class="nav_search">
                              <input type="text" v-model="deformationValue" placeholder="请输入文字搜索">
                              <div class="nav_search_button" @click="deformationFun"></div>
                            </div>
                          </div>
                        </div>

                      </el-popover>-->
                    <div class="nav_ul_li">

                        <el-button slot="reference"
                        >
                            <div
                                    @click="
                  refreshFun(
                    userInfo && userInfo.is_baodian == 1
                      ? 'deformation'
                      : 'deformationBind'
                  )
                "
                                    class="down nav_ul_li_title"
                            >
                                <span :class="routerIndex == 4 ? 'active' : ''">变形宝典</span>
                            </div>
                        </el-button
                        >
                    </div>
                    <div class="nav_ul_li nav_ul_li_marleft">
                        <el-popover
                                placement="bottom"
                                width="105"
                                trigger="hover"
                                :visible-arrow="false"
                        >
                            <div class="nav_daily_down_box">
                                <ul class="area">
                                    <li @click="refreshFun('integralShop')">积分商城</li>
                                    <li @click="refreshFun('auditionlist')">试听专区</li>
                                    <li @click="refreshFun('appDownload')">APP下载</li>
                                </ul>
                            </div>

                            <el-button slot="reference">
                                <h5 class="down nav_ul_li_title2">
                                    <div>
                                        <span class="more el-icon-more"></span>
                                    </div>
                                </h5>
                            </el-button>
                        </el-popover>
                        <!-- <div @click="refreshFun('appDownload')" class="nav_ul_li_title">
                          <span :class="routerIndex == 6 ? 'active' : ''">APP下载</span>
                        </div> -->
                    </div>
                </div>

                <!-- <div class="index_search_box">
                  <div class="index_search">
                    <div class="search_select" @mouseenter="selectShow = true" @mouseleave="selectShow = false">
                      <div class="search_select_box">
                        <div>{{search_name}}</div>
                      </div>
                    </div>
                    <span class="line"></span>
                    <div class="search_input">
                      <input type="text" v-model="keywords" onkeyup="this.value=this.value.replace(/[ ]/g,'')" placeholder="输入关键词" @keyup.enter="searchFun">
                    </div>
                    <div class="index_search_button" @click="searchFun"><img src="../assets/images/indexsearch.png" alt=""></div>
                  </div>
                </div> -->
            </div>
            <div class="nav_sign">
                <div class="spvip" @click="dailyFun('vip')">
                    <img src="../assets/images/viplogo/type_two_v.svg" alt=""/>视频会员
                </div>
                <div class="scvip" @click="dailyFun('vip')">
                    <img src="../assets/images/viplogo/type_two_s.svg" alt=""/>素材会员
                </div>
                <div class="news">
                    <!-- <img src="../assets/images/xiaoxi.png" alt=""> -->
                    <div class="tidings">
                        <svg
                                t="1665210101726"
                                class="icon"
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                p-id="10218"
                                width="24"
                                height="24"
                                @click="dailyFun('userNews')"
                        >
                            <path
                                    d="M192.032 631.402667V404.725333C192.032 228.330667 335.285333 85.333333 512 85.333333s319.968 142.997333 319.968 319.392v226.677334l60.608 121.013333c10.645333 21.237333-4.832 46.218667-28.618667 46.218667H160.042667c-23.786667 0-39.253333-24.981333-28.618667-46.218667l60.608-121.013333z m620.16 103.36l-40.842667-81.536a31.893333 31.893333 0 0 1-3.381333-14.282667V404.725333c0-141.12-114.602667-255.509333-255.968-255.509333S256.032 263.605333 256.032 404.725333V638.933333c0 4.96-1.162667 9.845333-3.381333 14.293334l-40.842667 81.525333h600.384z m-443.306667 152.32a31.893333 31.893333 0 0 1-4.149333-44.981334 32.032 32.032 0 0 1 45.056-4.138666A159.36 159.36 0 0 0 512 874.773333a159.36 159.36 0 0 0 102.186667-36.8 32.032 32.032 0 0 1 45.056 4.138667 31.893333 31.893333 0 0 1-4.16 44.981333A223.402667 223.402667 0 0 1 512 938.666667c-52.981333 0-103.2-18.453333-143.114667-51.594667z"
                                    p-id="10219"
                            ></path>
                        </svg>
                        <span v-if="readNo > 0">{{ readNo }}</span>
                        <div class="tidings_pop" v-if="userInfo.id != undefined">
                            <div class="tidings_pop_wrap">
                                <div class="tidings_pop_wrap_head">
                                    <h2>未读消息</h2>
                                    <a @click="read">全部已读</a>
                                </div>
                                <div class="tidings_pop_content" v-if="notice_list.length > 0">
                                    <div
                                            class="tidings_one"
                                            v-for="(item, index) of notice_list"
                                            :key="index"
                                            @click="toNewFun(item.type, item.id)"
                                    >
                                        [{{
                                        item.type == 1
                                            ? "系统"
                                            : item.type == 2
                                                ? "活动"
                                                : "作业"
                                        }}]{{ item.title }}
                                    </div>
                                </div>
                                <div class="emptys" v-else>
                                    <el-empty description="暂无数据" :image-size="80"></el-empty>
                                </div>

                                <div class="tidings_pop_all" @click="dailyFun('userNews')">
                                    查看所有
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nav_signs">
                    <div
                            class="nav_signs_img"
                            :style="{ marginRight: userShow ? '0' : '8px' }"
                            v-if="userShow == true"
                    >
                        <img
                                @click="dailyFun('user')"
                                :src="
                userShow ? userInfo.thumb : require('../assets/images/head.svg')
              "
                                alt=""
                        />
                        <div class="nav_signs_user" v-if="userShow">
                            <div class="nav_signs_user_box">
                                <div class="nav_signs_user_info" @click="dailyFun('user')">
                                    <img class="fl" :src="userInfo.thumb" alt=""/>
                                    <div class="fl user_info_user_box">
                                        <div class="user_info_user_name">
                                            <span>{{ userInfo.username }}</span>
                                            <template v-if="userInfo.is_lifelong_vip == 0">
                                                <img
                                                        v-if="
                            userInfo.video_vip == 1 &&
                            dealClassStyle(userInfo.video_end_time) == 1
                          "
                                                        src="../assets/images/spvip.svg"
                                                        alt=""
                                                />
                                                <img v-else src="../assets/images/spvipno.svg" alt=""/>
                                                <img
                                                        v-if="
                            userInfo.down_vip == 1 &&
                            dealClassStyle(userInfo.down_end_time) == 1
                          "
                                                        src="../assets/images/scvip.svg"
                                                        alt=""
                                                />
                                                <img v-else src="../assets/images/scvipno.svg" alt=""/>
                                            </template>
                                            <img v-else src="../assets/images/vsvip.svg" alt=""/>
                                        </div>
                                        <div class="user_info_user_signature">
                                            {{ userInfo.describe }}
                                        </div>
                                    </div>
                                    <div class="user_info_user_id">
                                        积分:{{
                                        userInfo.integral
                                        }}&nbsp;&nbsp;&nbsp;&nbsp;用户ID:{{ userInfo.id }}
                                    </div>
                                </div>
                                <div class="nav_signs_user_vip">
                                    <div
                                            class="nav_signs_user_vip_item1"
                                            :class="
                      userInfo.video_vip == 0 ||
                      (userInfo.video_vip == 1 &&
                        dealClassStyle(userInfo.video_end_time) == 2)
                        ? 'nav_signs_user_vip_item1s'
                        : ''
                    "
                                    >
                                        <div class="nav_signs_user_vip_l">
                                            <div
                                                    class="nav_signs_user_vip_l_one"
                                                    :style="
                          userInfo.is_lifelong_vip == 1
                            ? 'margin-top:12px;margin-bottom:12px'
                            : ''
                        "
                                            >
                                                {{
                                                userInfo.video_vip == 0 ||
                                                (userInfo.video_vip == 1 &&
                                                    dealClassStyle(userInfo.video_end_time) == 1)
                                                    ? "视频会员"
                                                    : "视频会员已过期"
                                                }}
                                            </div>
                                            <div
                                                    class="nav_signs_user_vip_l_time"
                                                    v-if="userInfo.is_lifelong_vip == 0"
                                            >
                                                {{
                                                userInfo.video_vip == 1 &&
                                                dealClassStyle(userInfo.video_end_time) == 1
                                                    ? userInfo.video_end_time.substr(0, 10) + "到期"
                                                    : "系统视频教程畅学"
                                                }}
                                            </div>
                                            <div class="nav_signs_user_vip_l_time" v-else>
                                                终身畅享海量视频教程
                                            </div>
                                        </div>
                                        <router-link
                                                to="/vip"
                                                class="nav_signs_user_vip_buy"
                                                v-if="userInfo.is_lifelong_vip == 0"
                                        >
                      <span>{{
                          userInfo.video_vip == 1 &&
                          dealClassStyle(userInfo.video_end_time) == 1
                              ? "续费"
                              : "立即开通"
                          }}</span>
                                        </router-link>
                                    </div>
                                    <div
                                            class="nav_signs_user_vip_item2"
                                            :class="
                      userInfo.down_vip == 0 ||
                      (userInfo.down_vip == 1 &&
                        dealClassStyle(userInfo.down_end_time) == 2)
                        ? 'nav_signs_user_vip_item2s'
                        : ''
                    "
                                    >
                                        <div class="nav_signs_user_vip_l">
                                            <div
                                                    class="nav_signs_user_vip_l_one"
                                                    :style="
                          userInfo.is_lifelong_vip == 1
                            ? 'margin-top:12px;margin-bottom:12px'
                            : ''
                        "
                                            >
                                                {{
                                                userInfo.down_vip == 0 ||
                                                (userInfo.down_vip == 1 &&
                                                    dealClassStyle(userInfo.down_end_time) == 1)
                                                    ? "素材会员"
                                                    : "素材会员已过期"
                                                }}
                                            </div>
                                            <div
                                                    class="nav_signs_user_vip_l_time"
                                                    v-if="userInfo.is_lifelong_vip == 0"
                                            >
                                                {{
                                                userInfo.down_vip == 1 &&
                                                dealClassStyle(userInfo.down_end_time) == 1
                                                    ? userInfo.down_end_time.substr(0, 10) + "到期"
                                                    : "畅享海量可商用素材"
                                                }}
                                            </div>
                                            <div class="nav_signs_user_vip_l_time" v-else>
                                                海量素材终身免费下载
                                            </div>
                                        </div>
                                        <router-link
                                                to="/vip"
                                                class="nav_signs_user_vip_buy"
                                                v-if="userInfo.is_lifelong_vip == 0"
                                        >
                      <span>{{
                          userInfo.down_vip == 1 &&
                          dealClassStyle(userInfo.down_end_time) == 1
                              ? "续费"
                              : "立即开通"
                          }}</span>
                                        </router-link>
                                    </div>
                                </div>
                                <div class="box_padding">
                                    <div class="nav_signs_user_tab">
                                        <div class="item item_l" @click="dailyFun('userDaily')">
                                            <img src="../assets/images/my3.svg" alt=""/>
                                            <div>我的主页</div>
                                        </div>
                                        <span></span>
                                        <div class="item" @click="dailyFun('userCollect')">
                                            <img src="../assets/images/my1.svg" alt=""/>
                                            <div>我的收藏</div>
                                        </div>
                                        <span></span>
                                        <div class="item item_r" @click="dailyFun('userDownload')">
                                            <img src="../assets/images/my2.svg" alt=""/>
                                            <div>我的下载</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                                class="nav_signs_user_nav_li"
                                                @click="dailyFun('userDaily')"
                                        >
                                            每日一字<span>展示你的作品</span>
                                        </div>
                                        <div
                                                class="nav_signs_user_nav_li"
                                                @click="dailyFun('userCoupon')"
                                        >
                                            优惠券码<span>各种优惠福利都在这里哦</span>
                                        </div>
                                        <div
                                                class="nav_signs_user_nav_li"
                                                @click="dailyFun('userOrder')"
                                        >
                                            订单中心<span>发票报销 轻松搞定</span>
                                        </div>
                                    </div>
                                    <div class="nav_signs_user_bottom">
                                        <div @click="dailyFun('userInfo')">账号信息</div>
                                        <div class="nav_signs_user_quit" @click="quitFun">
                                            退出账号
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="login_reg" v-if="!userShow">
                        <div class="" @click="isLogin = true">登录</div>
                        <div @click="registerFun">注册</div>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-top: 69px; min-height: 740px" id="test">
            <keep-alive
                    exclude="dailyWordDetail,materialDetail,videoDetail,rankingDetail,projectDetail,fontSearchDetail,user,deformation"
            >
                <router-view ref="nextRef"></router-view>
            </keep-alive>
        </div>
        <div class="footer" v-show="footer == false">
            <div class="mian">
                <div class="footer_top">
                    <div class="footer_top_l">
                        <div @click="seeShare(1)">关于我们</div>
                        <div @click="seeShare(5)">用户服务协议</div>
                        <div @click="seeShare(3)">隐私保护</div>
                        <div @click="seeShare(4)">联系我们</div>
                        <div @click="centerDialogVisible = true">建议反馈</div>
                    </div>
                    <!-- <div class="footer_top_r">
                      <div class="footer_top_r_wx">
                        <img src="../assets/images/weixin.png" alt="" />
                        <div class="footer_top_r_wx_img">
                          <img :src="webinfo.webcode" alt="" />
                        </div>
                      </div>
                      <a target="_blank" :href="webinfo.webwb">
                        <img src="../assets/images/weibo.png" alt="" />
                      </a>
                    </div> -->
                </div>
                <div class="footer_bottom">
                    <div class="footer_bottom_l">
                        <div>
                            联系人：{{ webinfo.webcontact }} <span></span> 微信：{{
                            webinfo.webwechat
                            }}
                            <span></span> 邮箱：{{ webinfo.webemail }}
                        </div>
                        <div>
                            Copyright © 2022 {{ webinfo.webcopyright }}
                            <a target="_blank" :href="webinfo.webicpurl">{{
                                webinfo.webicp
                                }}</a>
                        </div>
                    </div>
                    <div class="footer_bottom_r">
                        <div class="footer_bottom_r_name">微信公众号</div>
                        <div><img :src="webinfo.webwechatcode" alt=""/></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer_down" v-show="footer == true">
      <span @click="seeShare(5)">《用户协议》</span
      ><span @click="seeShare(3)">《隐私政策及应用权限》</span
        >开发者名称：山东字体江湖广告设计有限公司<br/>
            Copyright © 2022 {{ webinfo.webcopyright }}
            <a target="_blank" :href="webinfo.webicpurl">{{ webinfo.webicp }}</a>
        </div>

        <!-- 登录 -->
        <div class="mask" v-if="isLogin">
            <login ref="loginType"></login>
        </div>

        <!-- 建议反馈 -->
        <el-dialog
                title="建议反馈"
                :visible.sync="centerDialogVisible"
                width="500px"
        >
            <div class="dialog_div">
                <input type="text" placeholder="您的姓名" v-model="names"/>
            </div>
            <div class="dialog_div">
                <input type="number" placeholder="请输入手机号" v-model="mobile"/>
            </div>
            <textarea
                    style="resize: none"
                    placeholder="请输入反馈建议"
                    v-model="content"
            ></textarea>
            <div class="dialog_save" @click="addSaveFun">确定</div>
        </el-dialog>

    </div>
</template>

<script>
import login from "./login.vue";

export default {
    name: "public",
    components: {
        login,
    },
    data() {
        return {
            isNav: false,
            theme_list: [], //导航分类
            themeIndex: 0, //选中分类
            other_list: [], //导航二级
            footer: false,
            hot_words: [], //搜索分类
            search_name: "",
            keywords: "",
            navSecond: [],

            videoNav_list: [], //视频分类

            showIndex: 1,
            showList: false,

            selectShow: false,

            isLogin: false,

            userInfo: {}, //用户信息

            userShow: false,

            deformationValue: "", //变形宝典搜索内容

            centerDialogVisible: false, //建议反馈
            names: "",
            mobile: "",
            content: "",

            webinfo: {}, //网站信息
            navindex: [6, 6, 6, 6],

            routerIndex: 0, // 导航栏目选中

            searchOne: "", //字体素材弹窗搜索

            videoClass: [],

            readNo: 0, //未读数量
            notice_list: [],
            shadow: true,
            setTimeOut:null
        };
    },
    mounted() {
        window.loginShow = this.loginShow;
        this.initFun();
        if (localStorage.getItem("access_token")) {
            this.getUser();
            this.newsFun();
        }
        if (localStorage.getItem("userInfo")) {
            this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
        } else {
            this.readNo = 0;
        }
        console.log("userInfo", this.userInfo);

        this.$nextTick(() => {
            var browerWidth = window.innerWidth; //浏览器可视宽度
            if (1180 < browerWidth < 1565) {
                var baseWidth = 1565; //设计稿宽度
                var zoomValue = browerWidth / baseWidth; //缩放比例计算
                console.log(browerWidth);
                //document.getElementById("test").style.transform ="scale(" + zoomValue + "," + zoomValue + ")"; //mainContainer为主容器id
                if (zoomValue < 1) {
                    document.getElementById("test").style.zoom = zoomValue.toFixed(2);
                }

                // document.getElementById("test").style.transform ="scale(" + zoomValue + "," + zoomValue + ")";
                // document.getElementById("test").style['-moz-transform-origin'] = 'left top';
            }
        });
        let that=this;
        document.addEventListener("visibilitychange", function() {
            //浏览器切换事件
            if (document.visibilityState == "hidden") {
                //状态判断
                clearTimeout(that.setTimeOut);
            } else {
                that.newsFun();
            }
        });
    },
    methods: {
        //获取基础信息
        initFun() {
            var that = this;
            this.$api.POST(this.$face.default2, "", function (res) {
                that.videoClass = res.data.video_list;
                that.$store.commit("update", res.data);
                document.title = res.data.webinfo.webtitle;
            });
        },
        //获取用户信息
        getUser(id) {
            var that = this;
            this.$api.POST(
                this.$face.memberInfo,
                {
                    user_id: id || "",
                },
                function (res) {
                    if (!id) {
                        that.userInfo = res.data;
                        localStorage.setItem("is_benren", res.data.is_benren);
                        localStorage.setItem("userInfo", JSON.stringify(res.data));
                        that.$store.commit("userInfo", res.data);
                    } else {
                        // localStorage.setItem("userId", res.data.id);
                        // localStorage.setItem("is_benren", res.data.is_benren);
                        // localStorage.setItem("userInfo_other", JSON.stringify(res.data));
                        // that.$refs.nextRef.userInfo = res.data;
                        let routeUrl = that.$router.resolve({
                            name: "user",
                            query: {
                                userId: res.data.id,
                            },
                        });
                        window.open(routeUrl.href, "_blank");
                    }
                }
            );
        },
        //获取清单个数
        listFun() {
            var that = this;
            this.$api.POST(this.$face.download_cartIndex, "", function (res) {
                localStorage.setItem("total", res.data.total);
            });
        },
        //获取最新消息
        newsFun() {
            var that = this;
            if (localStorage.getItem("access_token")) {
                this.$api.POST(this.$face.noticeNews, "", function (res) {
                    that.readNo = res.data.read_no;
                    that.notice_list = res.data.data;

                    that.setTimeOut=setTimeout(() => {
                        that.newsFun();
                    }, 20000);
                });
            } else {
                that.newsFun();
            }
        },
        // 消息跳转页面
        toNewFun(type, id) {
            this.$router.push({
                name: "userNews",
                query: {
                    type: type,
                    id: id,
                    userId: this.userInfo.id,
                },
            });
        },
        //操作消息已读
        read() {
            var that = this;
            if (localStorage.getItem("access_token")) {
                this.$api.POST(this.$face.noticeRead, "", function (res) {
                    that.readNo = 0;
                    that.notice_list = [];
                    //   setTimeout(() => {
                    //     that.newsFun();
                    //   }, 5000);
                });
            } else {
                this.newsFun();
            }
        },
        searchFun() {
            // if(this.search_name == '字体素材'){
            this.$router.push({
                name: "material",
                query: {
                    keywords: this.keywords,
                },
            });
            // this.$router.push({name:'refresh',query:{usernext:'material'}})
            // }else if(this.search_name == '变形宝典'){
            //     this.$router.push({name:'deformation',query:{keywords:this.keywords}})
            // }else{
            //     this.$router.push({name:'fontSearch',query:{keywords:this.keywords}})
            // }
        },
        searchOneFun() {
            let routeData = null;
            if (this.$route.name == "material") {
                routeData = this.$router.resolve({
                    name: "refresh",
                    query: {
                        index: 0,
                        keywords: this.searchOne,
                    },
                });
            } else {
                routeData = this.$router.resolve({
                    name: "material",
                    query: {
                        index: 0,
                        keywords: this.searchOne,
                    },
                });
            }
            this.searchOne = "";
            window.open(routeData.href, "_blank");
        },
        // 选择搜索分类
        searchHotFun(row) {
            this.search_name = row.name;
        },
        //选择主题
        navtabFun(index, id) {
            this.themeIndex = index;

            // var that = this;
            // var params ={
            //     theme_id:id,
            //     whole:0
            // }
            // this.$api.POST(this.$face.themeList,params,function(res){
            //     res.data.other_list.forEach(element => {
            //         element.index = 0;
            //         element.isShow = false;
            //     });
            //     that.other_list = res.data.other_list;
            // })

            if (this.$route.name == "material") {
                this.$router.push({
                    name: "refresh",
                    query: {
                        index: 0,
                        themeIndex: this.themeIndex,
                    },
                });
            } else {
                this.$router.push({
                    name: "material",
                    query: {
                        index: 0,
                        themeIndex: this.themeIndex,
                    },
                });
            }
        },

        //选择主题筛选
        toJumpFun(id, index) {
            if (this.$route.name == "material") {
                this.$router.push({
                    name: "refresh",
                    query: {
                        id: id,
                        index: index + 1,
                        themeIndex: -1,
                    },
                });
            } else {
                this.$router.push({
                    name: "material",
                    query: {
                        id: id,
                        index: index + 1,
                        themeIndex: -1,
                    },
                });
            }
        },
        // 视频筛选
        toVideoJumpFun(id, index) {
            if (this.$route.name == "video") {
                this.$router.push({
                    name: "refresh",
                    query: {
                        id: id,
                        index: index,
                    },
                });
            } else {
                this.$router.push({
                    name: "video",
                    query: {
                        id: id,
                        index: index,
                    },
                });
            }
        },
        classMoreFun() {
            if (this.$route.name == "video") {
                this.$router.push({
                    name: "refresh",
                });
            } else {
                this.$router.push({
                    name: "video",
                });
            }
        },
        // 变形宝典搜索
        deformationFun() {
            if (!localStorage.getItem("access_token")) {
                this.loginShow();
                return;
            }
            let userInfo = JSON.parse(localStorage.getItem("userInfo"));
            console.log(userInfo);
            if (userInfo.is_baodian != 1) {
                this.popStatus = true;
                return;
            }

            this.$router.push({
                name: "deformation",
                query: {
                    search: this.deformationValue,
                },
            });
        },
        toArea(type) {
            // this.$router.push({
            //   name: "area",
            //   query: {
            //     type: type
            //   },
            // });
            let userQuery = {
                type: type,
            };
            this.$router.push({
                name: "refresh",
                query: {
                    usernext: "arrondi",
                    refresh: true,
                    query: userQuery,
                },
            });
        },
        //跳转页面
        dailyFun(e) {
            localStorage.setItem("is_benren", 1);
            localStorage.removeItem("userInfo_other");
            if (e == "user" && !localStorage.getItem("access_token")) {
                this.isLogin = true;
                return false;
            }
            localStorage.setItem("userId", this.userInfo.id);
            let routeData = null;
            if (e == "vip") {
                routeData = this.$router.resolve({
                    name: "refresh",
                    query: {
                        usernext: e,
                    },
                });
            } else {
                routeData = this.$router.resolve({
                    name: e,
                    query: {
                        userId: this.userInfo.id,
                    },
                });
            }
            window.open(routeData.href, "_blank");
        },

        // 退出登录
        quitFun() {
            localStorage.removeItem("access_token");
            localStorage.removeItem("userInfo");
            this.$store.commit("userInfo", "");
            this.userShow = false;
            this.$router.push({
                name: "/",
            });
        },

        // 全局登录方法
        loginShow() {
            this.isLogin = true;
        },
        // 注册
        registerFun() {
            this.isLogin = true;
            this.$nextTick(() => {
                this.$refs.loginType.loginType = 4;
            });
        },

        // 协议
        seeShare(id) {
            // this.$router.push({
            //   name: "userAgreement",
            //   query: {
            //     id: id,
            //   },
            // });
            let routeData = this.$router.resolve({
                name: "userAgreement",
                query: {
                    id: id,
                },
            });
            window.open(routeData.href, "_blank");

            // let routeUrl = this.$router.resolve({
            // 	path: "/userAgreement",
            // 	query: {
            // 		id: id
            // 	}
            // });
            // window.open(routeUrl.href, '_blank');
        },

        // 提交建议反馈
        addSaveFun() {
            var that = this;
            var params = {
                names: this.names,
                mobile: this.mobile,
                content: this.content,
            };
            this.$api.POST(this.$face.messageAdd, params, function (res) {
                that.centerDialogVisible = false;
                that.$utile.prompt("success", res.msg);
                (that.names = ""), (that.mobile = ""), (that.content = "");
            });
        },

        // //显示视频教程计算
        getTitleFun() {
            this.$nextTick(() => {
                setTimeout(() => {
                    var arry = [0, 0, 0];
                    var arry1 = [0, 0, 0];
                    this.videoClass.forEach((row, i) => {
                        var wid = 0;
                        if (this.$refs['getTitleRefss' + i]) {
                            console.log(this.$refs['getTitleRefss' + i].length)
                            this.$refs['getTitleRefss' + i].forEach((item, index) => {
                                wid += (item.offsetWidth + 50)

                                if (wid < 720) {
                                    this.navindex[i] = index;
                                }
                            })
                        } else {
                            this.navindex[i] = 6
                        }
                    })
                }, 0)
            })
        },
        //显示视频教程计算
        // getTitleFun() {
        //   this.navindex = [9, 4, 7, 6];
        //   // this.$nextTick(()=>{
        //   // 	setTimeout(()=>{
        //   // 		this.videoClass.forEach((row,i)=>{
        //   // 			var wid = 0;
        //   // 			if(this.$refs['getTitleRefss'+i]){
        //   // 				this.$refs['getTitleRefss'+i].forEach((item,index)=>{
        //   // 					wid += item.offsetWidth+40
        //   // 					console.log(111,wid )
        //   // 					if(wid<720){
        //   // 						this.navindex[i] = index
        //   // 						console.log(this.navindex)
        //   // 					}
        //   // 				})
        //   // 			}else{
        //   // 				this.navindex = [7,7,7]
        //   // 			}
        //   // 		})
        //   // 	},50)
        //
        //   // 	console.log(this.navindex )
        //   // 	this.$forceUpdate
        //   // })
        // },

        refreshFun(e) {
            localStorage.setItem("clearAll", 1);

            this.$router.push({
                name: "refresh",
                query: {
                    usernext: e,
                },
            });
            //window.open(routeData.href, "_blank");
        },

        //头像添加地址
        thumbFun() {
            if (this.userInfo.thumb.indexOf("http") == -1) {
                this.userInfo.thumb = require("../assets/images/head.png");
            }
        },
        backIndexTop() {
            this.$router.push({
                name: "refresh",
                query: {
                    usernext: "index",
                },
            });
        },
        //查看消息详情
        toLookFun(row) {
            if (row.is_user == 1) {
                // this.$parent.$parent.getUser(row.user_id);
                // this.$router.push({
                // 	name: 'refresh',
                // 	query: {
                // 		usernext: 'user'
                // 	}
                // })
                let userQuery = {
                    userId: row.user_id,
                };
                // this.$router.push({
                //   name: "refresh",
                //   query: {
                //     usernext: "userInfo",
                //     refresh: true,
                //     query: userQuery,
                //   },
                // });
                let routeUrl = this.$router.resolve({
                    name: "userInfo",
                    query: userQuery,
                });
                window.open(routeUrl.href, "_blank");
            } else {
                localStorage.setItem("workuser", row.user_id);
                var query = {
                    id: row.work_id,
                };
                // this.$router.push({
                //   name: "refresh",
                //   query: {
                //     usernext: "videoDetail",
                //     refresh: true,
                //     query: query,
                //   },
                // });
                let routeUrl = this.$router.resolve({
                    name: "videoDetail",
                    query: query,
                });
                window.open(routeUrl.href, "_blank");
            }
        },
        // 通过对比处理日期的时间戳返回样式的类名 天数
        dealClassStyle(time) {
            let timeString = new Date(time.substr(0, 10)).getTime();
            let nowTimeString = new Date().getTime();
            if (timeString < nowTimeString) {
                //过期时间
                return 2;
            } else {
                return 1;
            }
        },
    },
    watch: {
        "$store.state.navList"(newval) {
            this.theme_list = newval.theme_list;
            this.other_list = newval.other_list;
            this.hot_words = newval.hot_words;
            this.videoNav_list = newval.video_list;

            if (this.hot_words.length > 0) {
                this.search_name = this.hot_words[0].name;
            }
            this.webinfo = newval.webinfo;
        },
        showIndex(newval) {
            console.log("newval", newval);
            if (newval == 1) {
                this.showList = false;
            } else {
                this.showList = true;
            }
        },
        userInfo() {
            if (!localStorage.getItem("access_token")) {
                this.userShow = false;
            } else {
                this.userShow = true;
            }
            this.$forceUpdate;
        },
        "$store.state.userInfo"(newval) {
            console.log("监听监听");
            this.userInfo = newval;
            this.readNo = 0;
        },
        "userInfo.thumb"() {
            this.thumbFun();
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .publicBox{overflow: hidden;} */
.nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    min-width: 1400px;
    height: 69px;
    padding: 0 54px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 3px 6px 0 rgb(67 67 67 / 10%);
    transition: 0.3s;
    /* overflow: hidden; */
}

.publicBoxNoshadow .nav {
    box-shadow: none;
}

.navs {
    background: rgba(255, 255, 255, 1);
}

.nav_ul,
.nav_sign,
.nav_signs {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.nav_ul > div {
    padding: 0 16px;
    height: 100%;
}

.nav_ul > div .nav_ul_li_title {
    display: block;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 69px;
    letter-spacing: 0px;
    color: #333333;
    cursor: pointer;
    transition: 0.6s;
}

.nav_ul > div .nav_ul_li_title span.active {
    /* background-image:linear-gradient(to bottom right,#ffbb29,#5957FF,#5957FF);
      background-clip:text;
      -webkit-background-clip:text;
      -webkit-text-fill-color:transparent;  */
    color: #5957ff;
    font-weight: bold;
}

.nav_ul > div .nav_ul_li_title >>> .el-icon-arrow-down {
    font-size: 12px;
    margin-left: 5px;
}

.nav_ul > div .nav_ul_li_title .up {
    display: none !important;
}

.nav_ul > div:hover .nav_ul_li_title {
    /* background-image:linear-gradient(to bottom right,#ffbb29,#5957FF,#5957FF);
      background-clip:text;
      -webkit-background-clip:text;
      -webkit-text-fill-color:transparent;
      transition: 0.6s; */
    color: #5957ff;
}

.nav_ul > div:hover .nav_ul_li_title >>> .el-icon-arrow-down {
    transform: rotateZ(-180deg);
}

.nav_ul > div:hover .nav_ul_li_title .down {
    display: none !important;
}

.nav_ul > div:hover .nav_ul_li_title .up {
    display: block !important;
}

.nav_ul_li {
    position: relative;
}

.nav_ul_li_new {
    position: absolute;
    top: 16px;
    right: 12px;
    animation: new 2s infinite;
}

@keyframes new {
    0%,
    30%,
    50%,
    70%,
    100% {
        top: 16px;
    }

    40%,
    60% {
        top: 12px;
    }
}

/* 导航弹窗 */
.nav_li_down {
    width: 557px;
    position: absolute;
    top: 59px;
    left: 50%;
    margin-left: -278.5px;
    padding-top: 10px;
    z-index: 99;
}

.nav_ul_li >>> .el-button {
    border: none;
    padding: 0;
    font-size: 16px;
    background: transparent;
}

.nav_ul_li >>> .el-button:focus,
.nav_ul_li >>> .el-button:hover {
    background: transparent;
}

.nav_li_down_box {
    border-radius: 10px;
    padding: 16px 24px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: -1px 2px 8px 0px rgba(4, 0, 0, 0.11);
}

.nav_li_down_box::before {
    position: absolute;
    top: -9.5px;
    left: 50%;
    margin-left: -10px;
    content: "";
    border-top: 10px transparent dashed;
    border-left: 10px transparent dashed;
    border-right: 10px transparent dashed;
    border-bottom: 10px #fff solid;
    z-index: 6;
}

.nav_li_down_search {
    width: 100%;
    margin-bottom: 16px;
}

.nav_search {
    width: 100%;
    padding-left: 15px;
    padding-right: 73px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    position: relative;
    background-color: #f8fafb;
    border-radius: 10px;
}

.nav_search input {
    display: block;
    width: 100%;
    height: 100%;
    background: transparent;
    font-size: 14px;
    color: #666;
}

.nav_search input::placeholder {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 36px;
    letter-spacing: 0px;
    color: #999999;
}

.nav_search_button {
    position: absolute;
    top: 0;
    right: 0;
    width: 58px;
    height: 40px;
    border-radius: 0px 10px 10px 0px;
    background-color: #5957ff;
    background-image: url(../assets/images/search.png);
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: center center;
    cursor: pointer;
}

.nav_second_li {
    position: relative;
    margin-bottom: 5px;
}

.nav_second_title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9fafc;
    border-radius: 10px;
}

.nav_second_title img {
    width: 16px;
    margin-right: 8px;
}

.nav_second_r {
    width: 100%;
    padding-left: 129px;
    box-sizing: border-box;
}

.nav_second_ul {
    width: 100%;
    min-height: 37px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 12px;
    border-bottom: dashed 1px #eeeeee;
    position: relative;
    max-height: 85px;
    overflow: hidden;
}

.nav_second_ul li {
    margin-right: 35px;
    line-height: 43px;
    text-align: left;
    cursor: pointer;
    transition: 0.1s;
}

.nav_second_ul li.nav_second_ul_li {
    margin-right: 40px;
}

.nav_second_ul li:nth-child(6n) {
    margin-right: 0;
}

/* .nav_second_ul li.nav_second_ul_li:nth-child(5n){
		margin-right: 0;
	} */
.nav_second_ul li:nth-last-child(1) {
    margin-right: 0;
}

.nav_second_li:last-child {
    margin-bottom: 0;
}

.nav_second_li:last-child .nav_second_ul {
    border: none;
    padding-bottom: 0;
}

.nav_second_ul li:hover {
    color: #5957ff;
}

.nav_second_ul_more {
    color: #5957ff;
    display: flex;
    align-items: center;
}

.nav_second_ul_more2 {
    position: absolute;
    right: -5px;
    bottom: 12px;
    color: #5957ff;
    display: flex;
    align-items: center;
}

.nav_second_ul_more:hover {
    color: #5957ff;
}

.nav_second_ul_more img {
    transform: rotateZ(-90deg);
    margin-left: 4px;
}

/* 每日一字 */
.nav_daily_down {
    width: 630px;
    position: absolute;
    top: 59px;
    left: 50%;
    margin-left: -315px;
    padding-top: 10px;
}

.nav_daily_down_box {
    border-radius: 10px;
    padding: 24px 16px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: -1px 2px 8px 0px rgba(4, 0, 0, 0.11);
    display: flex;
    justify-content: space-between;
}

.nav_daily_down_box img {
    transition: 0.4s;
}

.nav_daily_down_box img:hover {
    transform: scale(1.08);
}

.nav_daily_down_box::before {
    position: absolute;
    top: -9.5px;
    left: 50%;
    margin-left: -10px;
    content: "";
    border-top: 10px transparent dashed;
    border-left: 10px transparent dashed;
    border-right: 10px transparent dashed;
    border-bottom: 10px #fff solid;
    z-index: 6;
}

.nav_ul > div .down {
    position: relative;
}

.nav_ul > div .down img {
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: -14px;
}

.nav_logo {
    /* width: 300px; */
    margin-right: 100px;
}

.nav_logo {
    display: flex;
    align-items: center;
}

.nav_logo_gg {
    display: none;
    margin-left: 23px;
}

.nav_sign {
    width: 456px;
    justify-content: end;
}

.nav_sign > div:not(:last-child) {
    margin-right: 28px;
    padding-left: 36px;
    position: relative;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 36px;
    letter-spacing: 0px;
    color: #333333;
}

.news {
    margin-right: 20px !important;
    padding-left: 0 !important;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.news span {
    display: block;
    line-height: 1;
    position: absolute;
    left: 9px;
    top: 7px;
    background-color: red;
    color: #fff;
    font-size: 12px;
    padding: 2px 4px;
    border-radius: 10px;
}

.nav_sign .scvip,
.nav_sign .spvip {
    cursor: pointer;
    transition: 0.1s;
}

.nav_sign .scvip:hover,
.nav_sign .spvip:hover {
    color: #5957ff;
}

.nav .scvip img,
.nav .spvip img {
    width: 32px;
    height: 30px;
    opacity: 0.8;
}

.nav_sign .scvip img,
.nav_sign .spvip img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -4px;
}

.nav_signs > div {
    cursor: pointer;
}

.nav_signs > div:not(:last-child) {
    margin-right: 16px;
}

.nav_signs > div.nav_signs_img {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    border: solid 2px #5957ff;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.nav_signs > div.nav_signs_img > img {
    width: 100%;
    height: 100%;
    border-radius: 32px;
}

.nav_signs_user {
    /* display: none; */
    opacity: 0;
    width: 360px;
    /* height: 461px; */

    position: absolute;
    top: 32px;
    right: 0;
    padding-top: 15px;
    z-index: -1;
}

.nav_signs_user_box {
    width: 100%;
    background-color: #ffffff;
    box-shadow: -1px 1px 23px 1px rgba(4, 0, 0, 0.11);
    border-radius: 10px;
    overflow: hidden;
}

.nav_signs > div.nav_signs_img:hover {
    overflow: inherit;
}

.nav_signs > div.nav_signs_img:hover .nav_signs_user {
    opacity: 1;
    transition: all 0.35s;
    z-index: 999;
}

.nav_signs_user_info {
    width: 100%;
    height: 74px;
    padding: 16px 28px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #eeeeee;
    position: relative;
}

.nav_signs_user_info > img {
    width: 46px;
    height: 46px;
    /* border: solid 2px #5957FF; */
    box-sizing: border-box;
    border-radius: 46px;
    overflow: hidden;
    margin-right: 8px;
}

.user_info_user_name {
    display: flex;
    align-items: center;
    font-size: 17px;

    font-stretch: normal;
    line-height: 1;
    letter-spacing: 0px;
    color: #333333;
    margin-bottom: 6px;
    margin-top: 4px;
}

.user_info_user_name span {
    display: block;
    max-width: 80px;
    overflow: hidden;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 7px;
}

.user_info_user_name img {
    width: 19px;
    height: 21px;
}

.user_info_user_name img:nth-child(3) {
    margin-left: 3px;
}

.user_info_user_signature {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    letter-spacing: 0px;
    color: #999999;
    width: 240px;
}

.user_info_user_id {
    position: absolute;
    top: 21px;
    right: 30px;
    font-size: 11px;
    color: #b1b2b2;
    line-height: 1;
}

.nav_signs_user_vip {
    /* margin: -29px auto 12px; */
    width: 100%;
    padding: 14px 28px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav_signs_user_vip_item1 {
    width: 145px;
    height: 87px;
    background: url(../assets/images/sxl_svipbg@2x.png) no-repeat;
    background-size: 100% 100%;
    padding-left: 15px;
    padding-top: 13px;
    box-sizing: border-box;
}

.nav_signs_user_vip_item1s {
    background: url(../assets/images/sxl_svipbg1@2x.png) no-repeat;
    background-size: 100% 100%;
}

.nav_signs_user_vip_item2 {
    width: 145px;
    height: 87px;
    background: url(../assets/images/sxl_scvipbg@2x.png) no-repeat;
    background-size: 100% 100%;
    padding-left: 15px;
    padding-top: 13px;
    box-sizing: border-box;
}

.nav_signs_user_vip_item2s {
    background: url(../assets/images/sxl_scvipbg1@2x.png) no-repeat;
    background-size: 100% 100%;
}

.nav_signs_user_vip_l_one {
    font-size: 16px;
    line-height: 1;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 5px;
}

.nav_signs_user_vip_item1s .nav_signs_user_vip_l_one {
    color: #5857ff;
}

.nav_signs_user_vip_item2s .nav_signs_user_vip_l_one {
    color: #995530;
}

.nav_signs_user_vip_l_one img {
    margin-right: 5px;
}

.nav_signs_user_vip_l_time {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 12px;
}

.nav_signs_user_vip_item1s .nav_signs_user_vip_l_time {
    color: #5857ff;
}

.nav_signs_user_vip_item2s .nav_signs_user_vip_l_time {
    color: #995530;
}

.nav_signs_user_vip_buy {
    display: block;
    width: 60px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 13px;
    background-color: #ffffff;
    position: relative;
    font-size: 10px;
    color: #5857ff;
}

.nav_signs_user_vip_item2 .nav_signs_user_vip_buy {
    color: #ff7c3b;
}

.nav_signs_user_vip_item2s .nav_signs_user_vip_buy {
    color: #995530;
}

/* .nav_signs_user_vip_buy span{
		width: 60px;
		height: 26px;
		background-color: #ffffff;
		border-radius: 13px;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 26px;
		letter-spacing: 0px;
		color: #7c6ff7;
		text-align: center;
		display: block;
		position: absolute;
		left: 0;
		top: -2px;
	} */
/* .nav_signs_user_vip_item2 .nav_signs_user_vip_buy{
		background-color: #5957FF;
	}
	.nav_signs_user_vip_item2 .nav_signs_user_vip_buy span{
		color: #5957FF;
	} */
.box_padding {
    padding: 0 28px 8px;
}

.nav_signs_user_tab {
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
}

.nav_signs_user_tab .item {
    width: 40%;
    cursor: pointer;
}

.nav_signs_user_tab .item img {
    width: 25px;
    height: 25px;
    margin: 0 auto 8px;
}

.nav_signs_user_tab .item div {
    font-size: 15px;
    line-height: 1;
    color: #333333;
    text-align: center;
}

.nav_signs_user_tab span {
    display: flex;
    width: 1px;
    height: 39px;
    background: #eeeeee;
}

.nav_signs_user_tab .item.item_l {
    width: 30%;
    padding-right: 30px;
    box-sizing: border-box;
}

.nav_signs_user_tab .item.item_r {
    width: 30%;
    padding-left: 30px;
    box-sizing: border-box;
}

.nav_signs_user_nav_li {
    width: 100%;
    height: 43px;
    border-bottom: 1px solid #f4f4f4;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #333333;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.nav_signs_user_nav_li span {
    font-size: 13px;
    color: #999;
    margin-left: 17px;
}

.nav_signs_user_bottom {
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav_signs_user_bottom > div {
    font-size: 15px;
    cursor: pointer;
}

.nav_signs_user_quit {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #333333;
    box-sizing: border-box;
    cursor: pointer;
}

.nav_signs_user_nav_li:hover {
    background-color: #f6f6f6;
}

/* 搜索 */
.index_search {
    width: 100%;
    height: 45px;
    background-color: #ffffff;
    box-shadow: 0px 1px 19px 2px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    margin: 12px auto 24px;
    position: relative;
    z-index: 999;
    padding-right: 160px;
    box-sizing: border-box;
    display: flex;
}

.index_search .line {
    display: block;
    width: 1px;
    height: 26px;
    background-color: #eeeeee;
    margin-top: 9.5px;
}

.search_select {
    width: 115px;
    height: 100%;
}

.search_select_box {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-left: 12px;
    box-sizing: border-box;
}

.search_select_box div {
    margin-right: 6px;
    font-size: 15px;
    letter-spacing: 0px;
    color: #333333;
}

.search_select_list {
    width: 100%;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
    overflow: hidden;
}

.search_select_list div {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
}

.search_select_list div:hover {
    background-color: #ebebff;
}

.search_input {
    width: calc(100% - 120px);
}

.search_input input {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #666;
    padding-left: 30px;
    box-sizing: border-box;
    font-size: 16px;
}

.search_input input::placeholder {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #999999;
}

.index_search_button {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 80px;
    height: 39px;
    background-image: linear-gradient(0deg, #5957ff 0%, #5957ff 100%);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.index_search_button img {
    width: 20px;
    /* height: 31px; */
}

.nav_list {
    position: relative;
    margin-left: -50px;
}

.index_search_box {
    width: 100%;
    position: absolute;
    left: 0;
    top: 70px;
    transition: 0.3s;
    opacity: 0;
    z-index: -1;
}

.nav_ul {
    transition: 0.3s;
}

.nav_list_s .nav_ul {
    opacity: 0;
}

.nav_list_s .index_search_box {
    top: 0;
    opacity: 1;
    z-index: 10;
}

/* 底部 */
.footer {
    background-color: #282828;
}

.footer_top {
    height: 62px;
    border-bottom: 1px solid rgba(204, 204, 204, 0.15);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

.footer_top_l {
    display: flex;
    align-items: center;
}

.footer_top_l div {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 36px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 0.36;
    cursor: pointer;
}

.footer_top_l div:not(:last-child) {
    margin-right: 36px;
}

.footer_top_r {
    display: flex;
    align-items: center;
}

.footer_top_r div:first-child {
    margin-right: 24px;
}

.footer_top_r img {
    width: 20px;
    height: 16px;
}

.footer_top_r_wx {
    position: relative;
}

.footer_top_r_wx_img {
    display: none;
    width: 120px;
    height: 120px;
    padding: 5px;
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    transform: translateX(-55%);
    bottom: 26px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 1px 1px 24px 0px rgba(0, 0, 0, 0.09);
}

.footer_top_r_wx_img img {
    width: 100%;
    height: 100%;
}

.footer_top_r_wx:hover .footer_top_r_wx_img {
    display: block;
}

.footer_bottom {
    padding: 11px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer_bottom_l {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 14px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 0.36;
    text-align: left;
}

.footer_bottom_l div:first-child {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.footer_bottom_l div span {
    display: block;
    margin: 0 12px 0 16px;
    width: 2px;
    height: 12px;
    background: #ffffff;
    opacity: 0.36;
}

.footer_bottom_l a {
    color: #fff;
}

.footer_bottom_r {
    display: flex;
}

.footer_bottom_r_name {
    width: 18px;
    writing-mode: vertical-lr;
    font-size: 12px;
    line-height: 10px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 2px;
    color: #ffffff;
    opacity: 0.58;
    text-align: left;
    border-top: 1px solid #5957ff;
    padding-top: 5px;
    box-sizing: border-box;
}

.footer_bottom_r img {
    width: 90px;
    height: 90px;
}

@media screen and (max-width: 1600px) {
    .nav_ul > div {
        padding: 0 8px;
    }

    .nav_sign {
        width: 450px;
    }
}

/* @media screen and (max-width: 1460px) {
  .nav_ul > div {
    padding: 0 15px;
  }
}
@media screen and (max-width: 1440px) {
  .nav_ul > div {
    padding: 0 15px;
  }
} */

/* 建议反馈 */
.dialog_div {
    width: 100%;
    height: 48px;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    margin-bottom: 15px;
    padding: 0 15px;
    border-radius: 8px;
}

.dialog_div input {
    width: 100%;
    height: 46px;
    line-height: 46px;
}

textarea {
    width: 100%;
    height: 100px;
    border: solid 1px #eeeeee;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 4px 15px;
    line-height: 28px;
    font-size: 14px;
}

textarea:focus-visible {
    outline: 0;
}

.dialog_save {
    width: 160px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin: 20px auto 0;
    background: #5957ff;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.tidings {
    line-height: 36px;
    padding-top: 13px;
    position: relative;
    cursor: default;
    cursor: pointer;
    overflow: hidden;
    width: 32px;
}

.tidings_pop {
    width: 285px;
    height: 323px;
    background-color: #ffffff;
    box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: solid 1px #fafafa;
    position: absolute;
    left: -210px;
    z-index: 999;
    opacity: 0;
    /* display: none */
    transition: all 0.35s;
}

.tidings_pop_wrap {
    position: relative;
    overflow: hidden;
    height: 323px;
}

.tidings_pop_wrap_head {
    position: absolute;
    left: 0;
    top: 0;
    height: 55px;
    padding: 0 25px;
    line-height: 55px;
    width: 235px;
}

.tidings_pop_wrap_head h2 {
    float: left;
    color: #636c78;
}

.tidings_pop_wrap_head a {
    float: right;
    color: #636c78;
    cursor: pointer;
}

.tidings_pop_content {
    width: 261px;
    height: 226px;
    margin: 55px auto 42px;
    overflow-x: hidden;
    overflow-y: auto;
}

.tidings_pop_content div {
    padding: 14px 15px;
    border: solid 1px #fff;
    border-radius: 10px;
    margin-bottom: 2px;
    height: 15px;
    line-height: 15px;
    cursor: pointer;
}

.tidings_pop_content div:hover {
    background-color: #f0f0f0;
    border: solid 1px #fafafa;
}

.tidings_pop_all {
    height: 42px;
    line-height: 42px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #f8f8f8;
    border-radius: 0px 0px 10px 10px;
    border: solid 1px #fafafa;
    width: 100%;
    text-align: center;
    cursor: pointer;
    color: #333333;
    font-size: 15px;
}

.tidings:hover {
    overflow: inherit;
}

.tidings:hover .tidings_pop {
    opacity: 1;
    transition: all 0.35s;
}

.emptys {
    padding-top: 40px;
}

.footer_down {
    width: 100%;
    height: 64px;
    background: #f6f6f6;
    padding-top: 22px;
    text-align: center;
    line-height: 24px;
}

.footer_down a {
    color: #333;
}

.footer_down span {
    cursor: pointer;
}

.nav_logo_img {
    cursor: pointer;
}

.login_reg {
    width: 93px;
    height: 24px;
    border: 1px #7575ff solid;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
}

.login_reg div {
    float: left;
    display: block;
    width: 46px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    font-size: 14px;
    color: #7575ff;
    transition: 0.3s;
}

.login_reg div:first-child {
    border-right: 1px #7575ff solid;
}

.login_reg div:hover {
    background: #deddff;
}

.area {
    font-size: 15px;
    color: #555555;
}

.area li {
    cursor: pointer;
    margin-bottom: 28px;
}

.area li:hover {
    color: #7575ff;
    transition: 0.3s;
}

.area li:last-child {
    margin-bottom: 0;
}

.more {
    border: none;
    font-weight: normal;
}

.nav_ul_li >>> .el-button {
    font-size: 24px;
    font-weight: normal;
}

.nav_ul_li_marleft {
    margin-left: 10px;
}

.nav_ul_li >>> .el-button:hover {
    color: #5857ff;
}

</style>
